
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import draggable from 'vuedraggable';
import { ICaracteristicaItem, ICaracteristicaSubItem } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'ItensListaSubItens',
  emits: ['update:itensListaSubItens'],
  props: {
    itensListaSubItens: {
      type: Array as () => ICaracteristicaItem[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    Card,
    MensagemSemDados,
    SelecionarSimNao,
  },
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const campoNovoValorItem = ref<HTMLElement | null>(null);
    const campoNovoValorSubItem = ref<HTMLElement | null>(null);

    const state = reactive({
      novoValor: '',
      novoValorSubItem: '',
      subItens: [] as ICaracteristicaSubItem[],
      indexCaracteristicaItem: 0,
      codigoCaracteristicaItem: 0,
      valorCaracteristicaItem: '',
      apresentarSubItems: false,
    });

    const computedItensListaSubItens = computed({
      get: () => props.itensListaSubItens,
      set: (val: ICaracteristicaItem[]) => {
        emit('update:itensListaSubItens', val);
      },
    });

    function removerItem(index:number) {
      computedItensListaSubItens.value.splice(index, 1);
    }

    function defineFocoCampoNovoItem() {
      if (campoNovoValorItem.value) {
        campoNovoValorItem.value.focus();
      }
    }
    function adicionarItem() {
      if (!UtilitarioGeral.valorValido(state.novoValor)) {
        apresentarMensagemAlerta('Por favor, defina um valor para o item!');
        defineFocoCampoNovoItem();
        return;
      }

      if (!UtilitarioGeral.validaLista(state.subItens)) {
        apresentarMensagemAlerta('É necessário definir pelo menos um Subitem!');
        return;
      }

      const itens = computedItensListaSubItens.value === undefined ? [] : computedItensListaSubItens.value;
      const itemCaracteristica:ICaracteristicaItem = {} as ICaracteristicaItem;
      itemCaracteristica.codigo = 0;
      itemCaracteristica.codigoCaracteristica = 0;
      itemCaracteristica.valor = state.novoValor;
      itemCaracteristica.ativo = true;
      itemCaracteristica.itens = state.subItens;
      itens.push(itemCaracteristica);
      state.novoValor = '';
      state.subItens = [];
      emit('update:itensListaSubItens', itens);
      defineFocoCampoNovoItem();
    }

    function removerSubItem(index:number) {
      state.subItens.splice(index, 1);
    }

    function adicionarSubItem() {
      if (!UtilitarioGeral.valorValido(state.novoValorSubItem)) {
        apresentarMensagemAlerta('Por favor, defina um valor para o subitem!');
        return;
      }

      const caracteristicaSubItem:ICaracteristicaSubItem = {} as ICaracteristicaSubItem;
      caracteristicaSubItem.codigo = 0;
      caracteristicaSubItem.codigoCaracteristicaItem = state.codigoCaracteristicaItem;
      caracteristicaSubItem.valor = state.novoValorSubItem;
      state.subItens.push(caracteristicaSubItem);
      state.novoValorSubItem = '';
      if (campoNovoValorSubItem.value) {
        campoNovoValorSubItem.value.focus();
      }
    }

    function apresentarNovosSubItensCaracteristica() {
      if (!UtilitarioGeral.valorValido(state.novoValor)) {
        apresentarMensagemAlerta('Por favor, defina um valor para o item!');
        defineFocoCampoNovoItem();
        return;
      }

      state.indexCaracteristicaItem = -1;
      state.codigoCaracteristicaItem = 0;
      state.valorCaracteristicaItem = state.novoValor;
      state.subItens = [];
      state.apresentarSubItems = true;
    }

    function apresentarSubItensCaracteristica(index:number, caracteristicaItem: ICaracteristicaItem) {
      state.indexCaracteristicaItem = index;
      state.codigoCaracteristicaItem = caracteristicaItem.codigo;
      state.valorCaracteristicaItem = caracteristicaItem.valor;
      if (UtilitarioGeral.validaLista(caracteristicaItem.itens)) {
        state.subItens = UtilitarioGeral.instanciaObjetoLocal(caracteristicaItem.itens);
      } else {
        state.subItens = [];
      }
      state.apresentarSubItems = true;
    }

    function ocultarSubItensCaracteristica() {
      state.indexCaracteristicaItem = -1;
      state.codigoCaracteristicaItem = 0;
      state.valorCaracteristicaItem = '';
      state.novoValorSubItem = '';
      state.apresentarSubItems = false;
    }
    function confirmarSubItensCaracteristica() {
      if (state.indexCaracteristicaItem >= 0) {
        const itens = computedItensListaSubItens.value;
        itens[state.indexCaracteristicaItem].itens = state.subItens;
        emit('update:itensListaSubItens', itens);
        state.subItens = [];
      }

      ocultarSubItensCaracteristica();
    }

    return {
      state,
      computedItensListaSubItens,
      campoNovoValorItem,
      campoNovoValorSubItem,
      removerItem,
      adicionarItem,
      removerSubItem,
      adicionarSubItem,
      apresentarNovosSubItensCaracteristica,
      apresentarSubItensCaracteristica,
      confirmarSubItensCaracteristica,
      ocultarSubItensCaracteristica,
    };
  },
});

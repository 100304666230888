
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import SelecionarTipoCaracteristica from '@/components/Cadastros/Produtos/Caracteristicas/SelecionarTipoCaracteristica.vue';
import ItensListaSimples from '@/components/Cadastros/Produtos/Caracteristicas/ItensListaSimples.vue';
import ItensListaSubItens from '@/components/Cadastros/Produtos/Caracteristicas/ItensListaSubItens.vue';
import ItensCores from '@/components/Cadastros/Produtos/Caracteristicas/ItensCores.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoCaracteristica from '@/servicos/Cadastros/Produtos/ServicoCaracteristica';
import { ICaracteristica, ICaracteristicaEmpresa } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/Cadastros/Produtos/ETipoCaracteristica';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';

export default defineComponent({
  name: 'CaracteristicaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarSimNao,
    RequisicaoModal,
    SelecionarTipoCaracteristica,
    ItensListaSimples,
    ItensListaSubItens,
    ItensCores,
    SelecionarEmpresaCadastroCompartilhado,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes,
      preencherPermissoesDados, preencherEmpresasComEstrategiaPermissaoDados, apresentarMensagemSucesso, defineEmpresasSelecionadasCadastroCompartilhado,
      filtrarPermissaoDadosUsuarioMultiEmpresas, verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoCaracteristica = new ServicoCaracteristica();
    telaBase.identificadorRecurso = 'CADASTRO_CARACTERISTICAS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_CARACTERISTICAS';

    const state = reactive({
      caracteristica: {} as ICaracteristica,
    });

    function objetoInicial() {
      state.caracteristica = {} as ICaracteristica;
      state.caracteristica.codigo = 0;
      state.caracteristica.ativo = true;
      state.caracteristica.filtro = false;
      state.caracteristica.itens = [];
      state.caracteristica.empresas = [];
      telaBase.empresasSelecionadas = [];
    }

    function preparaPersistenciaEmpresas() {
      const caracteristicaEmpresas : ICaracteristicaEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.caracteristica.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            caracteristicaEmpresas.push(empresaExistente);
          } else {
            const caracteristicaEmpresa: ICaracteristicaEmpresa = { codigo: 0, codigoCaracteristica: state.caracteristica.codigo, codigoEmpresa };
            caracteristicaEmpresas.push(caracteristicaEmpresa);
          }
        });
      }
      state.caracteristica.empresas = caracteristicaEmpresas;
    }

    function atualizaOrdemItens() {
      if (state.caracteristica.tipo === ETipoCaracteristica.Cor
      || state.caracteristica.tipo === ETipoCaracteristica.ListaSimples
      || state.caracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
        if (UtilitarioGeral.validaLista(state.caracteristica.itens)) {
          for (let indexItem = 0; indexItem < state.caracteristica.itens.length; (indexItem += 1)) {
            state.caracteristica.itens[indexItem].ordem = indexItem;

            if (state.caracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
              for (let indexSubItem = 0; indexSubItem < state.caracteristica.itens[indexItem].itens.length; (indexSubItem += 1)) {
                state.caracteristica.itens[indexItem].itens[indexSubItem].ordem = indexSubItem;
              }
            }
          }
        }
      }
    }
    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      atualizaOrdemItens();
      apresentarBarraProgresso();
      preparaPersistenciaEmpresas();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoCaracteristica.incluir(state.caracteristica);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoCaracteristica.alterar(state.caracteristica);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          telaBase.empresasSelecionadas = [props.operacao.empresaSelecionada];
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.caracteristica = await servicoCaracteristica.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (UtilitarioGeral.validaLista(state.caracteristica.empresas)) {
            state.caracteristica.empresas.forEach((e) => {
              telaBase.empresasSelecionadas.push(e.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
      ETipoCaracteristica,
    };
  },
});

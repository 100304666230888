
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import draggable from 'vuedraggable';
import { ICaracteristicaItem } from '@/models/Entidades/Cadastros/Produtos/ICaracteristica';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'ItensListaSimples',
  emits: ['update:itensListaSimples'],
  props: {
    itensListaSimples: {
      type: Array as () => ICaracteristicaItem[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    Card,
    SelecionarSimNao,
    MensagemSemDados,
  },
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const campoNovoValorItem = ref<HTMLElement | null>(null);

    const state = reactive({
      novoValor: '',
    });

    const computedItensListaSimples = computed({
      get: () => props.itensListaSimples,
      set: (val: ICaracteristicaItem[]) => {
        emit('update:itensListaSimples', val);
      },
    });

    function removerItem(index:number) {
      computedItensListaSimples.value.splice(index, 1);
    }

    function defineFocoCampoNovoItem() {
      if (campoNovoValorItem.value) {
        campoNovoValorItem.value.focus();
      }
    }

    function adicionarItem() {
      if (!UtilitarioGeral.valorValido(state.novoValor)) {
        apresentarMensagemAlerta('Por favor, defina um valor para o item!');
        defineFocoCampoNovoItem();
        return;
      }

      const itens = computedItensListaSimples.value;
      const itemCaracteristica:ICaracteristicaItem = {} as ICaracteristicaItem;
      itemCaracteristica.codigo = 0;
      itemCaracteristica.codigoCaracteristica = 0;
      itemCaracteristica.valor = state.novoValor;
      itemCaracteristica.ativo = true;
      itens.push(itemCaracteristica);
      state.novoValor = '';
      emit('update:itensListaSimples', itens);
    }

    return {
      state,
      computedItensListaSimples,
      campoNovoValorItem,
      removerItem,
      adicionarItem,
    };
  },
});
